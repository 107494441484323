<template>
  <div class="page">
    <Header></Header>
    <h1>{{ title }}</h1>
    <Navigation></Navigation>
    <main class="creators">
      <Nominee
        v-for="(creator, index) in creators"
        @click="nomineeSelected(index)"
        :key="index"
        :customId="index"
        :category="category"
        :name="creator.name"
        :jpg="creator.jpg"
        :webp="creator.webp"
      ></Nominee>
    </main>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import Navigation from "@/components/Navigation.vue";
import Nominee from "@/components/Nominee.vue";
import Header from "@/components/HeaderVote.vue";

export default defineComponent({
  name: "Stora Hjärta",
  components: {
    Navigation,
    Nominee,
    Header,
  },
  data() {
    return {
      category: "storahjarta",
      title: "Årets Stora Hjärta",
    };
  },
  methods: {
    nomineeSelected(nomineeIndex) {
      this.$store.dispatch("selectNominee", {
        category: this.category,
        nomineeIndex: nomineeIndex,
      });
      this.$store.dispatch("setVotingStepDone", { currentStep: 1 });
      setTimeout(() => {
        this.$router.push("forebild");
      }, 250);
    },
  },
  computed: {
    ...mapGetters({
      creators: "getStoraHjartaCreators",
    }),
  },
});
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 0.7rem;
  text-align: center;
  color: white;
  text-transform: capitalize;
}
</style>
