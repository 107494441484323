<template>
  <div class="page">
    <Header></Header>
    <h1>{{ title }}</h1>
    <Navigation></Navigation>
    <main class="creators">
      <Nominee
        v-for="(creator, index) in creators"
        @click="nomineeSelected(index)"
        :key="index"
        :customId="index"
        :category="category"
        :name="creator.name"
        :jpg="creator.jpg"
        :webp="creator.webp"
      ></Nominee>
    </main>

    <a @click="this.$router.back()">
      <i class="fas fa-angle-left"></i>
    </a>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import Navigation from "@/components/Navigation.vue";
import Nominee from "@/components/Nominee.vue";
import Header from "@/components/HeaderVote.vue";

export default defineComponent({
  name: "Platform",
  components: {
    Navigation,
    Nominee,
    Header,
  },
  data() {
    return {
      category: "platform",
      title: "Föredragen Platform",
    };
  },
  methods: {
    nomineeSelected(nomineeIndex) {
      this.$store.dispatch("selectNominee", {
        category: this.category,
        nomineeIndex: nomineeIndex,
      });
      this.$store.dispatch("setVotingStepDone", { currentStep: 4 });
      setTimeout(() => {
        this.$router.push("submit");
      }, 250);
    },
  },
  computed: {
    ...mapGetters({
      creators: "getPreferredPlatform",
    }),
  },
  beforeCreate() {
    let prevStepDone = this.$store.getters.getVotingStepStatus(4);
    if (!prevStepDone) {
      window.location.href = "/";
    }
  },
});
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 0.7rem;
  text-align: center;
  color: white;
  text-transform: capitalize;
}
</style>
